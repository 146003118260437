import React, { PureComponent } from 'react';

import Page from '../../pages/Page/Page';
import scriptCache from '../../utils/scriptCache';
import { appEnvironment } from '../../config/constants';

const config = {
  attributes: true,
  attributeOldValue: true,
  characterData: true,
  characterDataOldValue: true,
  childList: true,
  subtree: true
};

class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.scriptCache = null;
    this.force = false;

    this.subscriber = this.subscriber.bind(this);
    this.wrapInner = this.wrapInner.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);

    this.url = appEnvironment === 'production'
      ? 'leasing.gplc.uk'
      : 'graingerstage.pexsoftware.com';
  }

  UNSAFE_componentWillMount() {
    this.observer = new MutationObserver(this.subscriber);
    this.observer.observe(document.body, config);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  componentDidMount() {
    const addSpan = (event) => {
      if(event.data === 'recaptcha-setup') {
        const buttons = document && document.querySelectorAll('label.btn.btn-default')
        for(let i = 0; i < buttons.length; i++) {
          this.wrapInner(buttons[i], 'span')
        }
      }
    }
    window.addEventListener("message", addSpan)

  }

  subscriber(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.target.id === 'g-recaptcha') {
        this.form = document.forms[0];
        this.form.addEventListener('submit', () => this.handleSubmit());
        window.pexComplete = true;
      }
    });
  }

wrapInner(parent, wrapper) {
    if (typeof wrapper === "string") {
      wrapper = document.createElement(wrapper);
      wrapper.appendChild(parent.firstChild);
    }
    parent.appendChild(wrapper).setAttribute('class', "btn-span");
    while (parent.firstChild !== wrapper) {
      wrapper.appendChild(parent.firstChild);
    }
  }

  handleSubmit() {
    window.scrollTo(0, 0);
  }

  loadScripts() {
    if (!this.scriptCache) {
      this.scriptCache = new scriptCache([
        `https://${this.url}/widgets/pex-generic-webform-widget.js`,
        'https://www.google.com/recaptcha/api.js?render=explicit'
      ], this.force);
    }
  }

  render() {
    const RegisterForm = ({ designComponent }) => (
      <div>
        {designComponent && <designComponent.component {...designComponent.props} />}
        <div className="gr-grainger-contact">
          <div className="gr-grainger-form">
            <div className="gr-grainger-form__form">
              <div
                id="pex-generic-webform-widget"
                className="pex-generic-webform-widget"
                api={`https://${this.url}/json-interface`}
                countrydefaultvalue="GB"
                thanksmessage="See you soon at <strong><BUILDING_NAME></strong> in <LOCATION_NAME>"
                webform="Website_register_form_THESILVERYARD"
                ref={() => { this.loadScripts(); }}
              />
            </div>
          </div>
        </div>
      </div>

    )
    return <Page {...this.props}>
      {pageComponents => {
        const comp = Object.values(pageComponents)[0]
        if (comp && comp.component) {
          return (
              <RegisterForm designComponent={comp}/>
          )
        }
        return <RegisterForm />
      }}
    </Page>;
  }
}

export default Contact;
